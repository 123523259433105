<template>
<v-app-bar app :color="$route.name === 'Home' ? 'transparent' :'white'" flat absolute class="py-2 nav-public">
  <div class="d-flex align-center">
    <router-link to="/" class="d-flex">
      <template v-if="$router.currentRoute.name=='Home'">
        <img
          alt="Vuetify Logo"
          class="shrink mr-2 d-inline-flex"
          contain
          src="@/assets/Logo.png"
          transition="scale-transition"
          width="60"
        />
        <img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="@/assets/Logo-Text-White.png"
        width="100"
        />
      </template>
      <template v-else>
        <div class="logos d-flex align-center">
          <img
          alt="Vuetify Logo"
          class="shrink mr-2 d-inline-flex"
          contain
          src="@/assets/Logo-Color.png"
          transition="scale-transition"
          width="60"
          />
          <img
          v-if="$vuetify.breakpoint.mdAndUp"
          alt="Vuetify Name"
          class="logo-text shrink mt-1 hidden-sm-and-down d-inline-flex"
          contain
          src="@/assets/Logo-Text.png"
          />
        </div>
      </template>
    </router-link>
  </div>

  <v-spacer></v-spacer>
  <div class="nav-links" v-if="$vuetify.breakpoint.mdAndUp">
    <router-link class="mr-10" to="/about">About</router-link>
    <router-link class="mr-10" to="/features">Features</router-link>
    <router-link class="mr-10" to="/pricing">Pricing</router-link>
    <router-link class="mr-10" to="/become">Become Meetable</router-link>
  </div>
  <div class="nav-links" v-else>
    <router-link class="mr-1" to="/about">About</router-link>
    <router-link class="mr-1" to="/pricing">Pricing</router-link>
    <!-- <router-link to="/become">Become Meetable</router-link> -->
  </div>

  <!-- <v-spacer></v-spacer> -->

  <!-- <v-btn class="mr-4">
    <span>Get Meetable</span>
  </v-btn> -->
  <template v-if="!isAuthenticated">
    <v-btn class="login" :color="$route.name == 'Home' ? 'white':'grey lighten-1'" outlined depressed  to="/login" v-if="$vuetify.breakpoint.mdAndUp">
      <span :class="{'black--text' : $route.name != 'Home', 'white--text': $route.name == 'Home'}">Login</span>
    </v-btn>
    <v-btn class="ml-2" color="black" depressed dark to="/login" :class="{'bg-loop' : $route.name != 'Home'}" >
      <span :class="{'text-shadow' : $route.name != 'Home'}" v-if="$vuetify.breakpoint.mdAndUp">Become Meetable</span>
      <span :class="{'text-shadow' : $route.name != 'Home'}" v-else>Get Meetable</span>
    </v-btn>
  </template>
  <v-btn class="mr-2 py-5" to="/my" v-else text>
    <!-- <span class="pr-2">{{user.firstName}}</span> -->
    <template v-if="!$isEmpty(user.photoURL)">
      <v-avatar color="primary" size="32"><img :src="user.photoURL.thumb" alt=""></v-avatar>
    </template>
    <template v-else>
      <v-avatar color="primary" size="32"><img :src="user.basicAvatar" alt=""></v-avatar>
    </template>
  </v-btn>
</v-app-bar>
</template>

<script>
import { storage } from '@/firebase';
export default {
  name: 'mainNav',

  computed: {
    isAuthenticated: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
  },

  data: () => ({
    photoURL: null,
  }),
  methods:{

  },
  mounted(){

  }
};
</script>


<style lang="scss">
.nav-public{
  height: 84px!important;
  .logos{
    // width: 150px;
    .logo-text{
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .nav-links{
    a{
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
      padding: 4px 8px;
      color: rgba(0,0,0,.50)!important;
      // color: white;
      font-size: 14px;
      &.router-link-active{
        color: black!important;
        border-bottom: 2px solid black;
      }
    }
  }
  .login{
    border-width: 3px;
  }
}
.mobile .nav-public{
  height: 64px!important;
  .v-toolbar__content{
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
